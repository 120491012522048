/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'

import { CheckCircleOutline, Highlight, RemoveCircleOutline } from '@material-ui/icons'
import { NumberBubble } from 'components/widgets'
import {
  HealthLabelsKeys,
  SHORT_DAY,
  DATE,
  LOCAL_TIME,
  LOCAL_DATE,
  AlertsColumnKeys,
  HealthTypesKeys,
} from 'lib/constants'
import {
  AlertSession,
  NameNumberValuePair,
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  tc,
  trans,
  TranslationGroup,
  TranslationKey,
  ucfirst,
} from 'lib/types'
import { createSortOption, invertIfDescending, simpleCompare, getAlertTimes } from 'lib/utils'
import { subDays, differenceInDays } from 'date-fns'
import { formatTime, parseTime } from 'lib/utils'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import { generateCSV } from 'lib/utils/csv'
import { Orgs } from 'models'
import { HealthIcon, HealthStatus } from 'components/widgets/HealthIcon'
import { getAlertsCSV, getV5AlertsCSV } from 'models/api'

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export function getFilterOptions() {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  return [
    tc(translation.all_alerts),
    tc(translation.active_no_response),
    tc(translation.in_progress),
    tc(translation.resolved),
  ]
}

export function getFilterOptionKeys() {
  return [
    HealthLabelsKeys.UNKNOWN,
    HealthLabelsKeys.ACTIVE_NO_RESPONSE,
    HealthLabelsKeys.ACTIVE_IN_PROGRESS,
    HealthLabelsKeys.RESOLVED,
  ]
}

export const getSortOptions: () => SortOption<AlertsColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_VIEW)
  const common = trans.common()
  // return _.flatten(
  //   _.map(
  //     _.filter(AlertsColumnKeys, c => (c === AlertsColumnKeys.Health || c === AlertsColumnKeys.Date ? true : false)), // Exclude 'health', 'buttonConnected' key,
  //     c => [
  //       createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
  //       createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
  //     ],
  //   ),
  // )
  const options = [
    createSortOption(AlertsColumnKeys.Date, SortOrder.Descending, `${translation['date']} ↓`),
    createSortOption(AlertsColumnKeys.Date, SortOrder.Ascending, `${translation['date']} ↑`),
    createSortOption(AlertsColumnKeys.Name, SortOrder.Descending, `${translation['name']} ↓`),
    createSortOption(AlertsColumnKeys.Name, SortOrder.Ascending, `${translation['name']} ↑`),
    createSortOption(AlertsColumnKeys.Health, SortOrder.Descending, `${translation[common.health]} ↓`),
    createSortOption(AlertsColumnKeys.Health, SortOrder.Ascending, `${translation[common.health]} ↑`),
  ]
  return options
}

function getAlertDateString(alert: AlertSession) {
  const common = trans.common()
  const { startTime, endTime } = getAlertTimes(alert)
  const startTimeString = formatTime(startTime, DATE)
  const endTimeString = formatTime(endTime, DATE, common.ongoing)

  if (startTimeString !== endTimeString) {
    return `${startTimeString} - ${endTimeString}`
  } else {
    return startTimeString
  }
}

function getAlertTimeString(alert: AlertSession) {
  const common = trans.common()
  const { startTime, endTime } = getAlertTimes(alert)
  return `${formatTime(startTime, LOCAL_TIME)} - ${formatTime(endTime, LOCAL_TIME, common.ongoing)}`
}

export const getColumnConfigs: () => TableColumnConfig<AlertsColumnKeys, AlertSession>[] = () => {
  const common = trans.common()
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_VIEW)
  const healthLabels: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  return [
    {
      header: AlertsColumnKeys.Name,
      label: translation[common.name],
      renderFn: (alertSession: AlertSession) => <div>{alertSession.initiatorName}</div>,
    },

    {
      header: AlertsColumnKeys.Health,
      label: translation[common.health],
      renderFn: (alert: AlertSession) => {
        const health = getAlertHealth(alert)
        let status = HealthStatus.UNKNOWN

        switch (health) {
          case HealthLabelsKeys.ACTIVE_NO_RESPONSE:
            status = HealthStatus.ACTIVE_NO_RESPONSE
            break
          case HealthLabelsKeys.ACTIVE_IN_PROGRESS:
            status = HealthStatus.MODERATE
            break
          case HealthLabelsKeys.RESOLVED:
            status = HealthStatus.HEALTHY
            break
        }

        return <HealthIcon label={ucfirst(healthLabels[health])} status={status} />
      },
    },
    {
      header: AlertsColumnKeys.Responders,
      label: translation[AlertsColumnKeys.Responders],
      renderFn: (alert: AlertSession) => <NumberBubble value={getRespondersCount(alert)} />,
    },
    {
      header: AlertsColumnKeys.Resolution,
      label: 'Resolution', //tc(translation[AlertsColumnKeys.Resolution]), // I need to update the translation once we agree upon the time key
      renderFn: getResolution,
    },
    {
      header: AlertsColumnKeys.Date,
      label: translation['date'],
      renderFn: (alert: AlertSession) => {
        return (
          <div
            css={{
              whiteSpace: 'nowrap',
            }}
          >
            {getAlertDateString(alert)}
          </div>
        )
      },
    },
    {
      header: AlertsColumnKeys.Time,
      label: translation[AlertsColumnKeys.Time],
      renderFn: (alert: AlertSession) => {
        return (
          <div
            css={{
              whiteSpace: 'nowrap',
            }}
          >
            {getAlertTimeString(alert)}
          </div>
        )
      },
    },
  ]
}

export function getAlertHealth(alertSession: AlertSession): string {
  const { status, activeRespondersCount } = alertSession

  if (status === HealthTypesKeys.OPEN && activeRespondersCount <= 0) return HealthLabelsKeys.ACTIVE_NO_RESPONSE

  if (status === HealthTypesKeys.OPEN && activeRespondersCount > 0) return HealthLabelsKeys.ACTIVE_IN_PROGRESS

  if (status === HealthTypesKeys.RESOLVED) return HealthLabelsKeys.RESOLVED

  return HealthLabelsKeys.UNKNOWN
}

export function getNumberOfAlertsLastWeek(alerts: AlertSession[]): NameNumberValuePair[] {
  const now = new Date()
  const WEEK_LENGTH = 6

  // Reduce alerts to a single array with the number of alerts per day
  return (
    alerts
      .reduce(
        (weeklyAlerts: number[], alert: AlertSession): number[] => {
          // Date must be converted to local timezone, then parsed back to date object
          const daysAgo = Math.abs(differenceInDays(parseTime(formatTime(alert.firstTime, LOCAL_DATE)), now))

          if (daysAgo <= WEEK_LENGTH) weeklyAlerts[WEEK_LENGTH - daysAgo] += 1

          return weeklyAlerts
        },
        [0, 0, 0, 0, 0, 0, 0],
      )
      // Convert to day of the week / value pair
      .map(
        (value, daysAgo): NameNumberValuePair => ({
          name: formatTime(subDays(now, WEEK_LENGTH - daysAgo), SHORT_DAY) as string,
          value,
        }),
      )
  )
}

export function getAlertTooltip(alertSession: AlertSession) {
  const common: TranslationGroup = trans.common()
  return common[getAlertHealth(alertSession)]
}

export function getFilterAlertComparison(alert: AlertSession, filterBy: string) {
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  return idx === 0 ? true : getAlertHealth(alert) === keys[idx]
}

// const compareAlertStartTimes = (a: AlertSession, b: AlertSession): number => b.firstTime - a.firstTime

// const compareAlertHealth = (a: AlertSession, b: AlertSession): number => {
//   const keys = getFilterOptionKeys()
//   return keys.findIndex(h => getAlertHealth(a) === h) - keys.findIndex(h => getAlertHealth(b) === h)
// }

// export function getSortAlertCompareFn(sortBy: SortBy<AlertsColumnKeys>): (a: AlertSession, b: AlertSession) => number {
//   switch (sortBy.field) {
//     case AlertsColumnKeys.Name:
//       return (a: AlertSession, b: AlertSession) =>
//         sortBy.order === SortOrder.Ascending
//           ? collator.compare(a.initiatorName, b.initiatorName)
//           : collator.compare(b.initiatorName, a.initiatorName)

//     case AlertsColumnKeys.Health:
//       return (a: AlertSession, b: AlertSession) =>
//         normalizeCompareResult(
//           invertIfDescending(compareAlertHealth(a, b) || -compareAlertStartTimes(b, a), sortBy.order),
//         )

//     case AlertsColumnKeys.Responders:
//       return (a: AlertSession, b: AlertSession) =>
//         normalizeCompareResult(
//           invertIfDescending(
//             getRespondersCount(a) - getRespondersCount(b) || compareAlertStartTimes(a, b),
//             sortBy.order,
//           ),
//         )

//     case AlertsColumnKeys.Resolution:
//       return (a: AlertSession, b: AlertSession) => {
//         return normalizeCompareResult(
//           invertIfDescending(compareAlertHealth(a, b) || getAlertDuration(b) - getAlertDuration(a), sortBy.order),
//         )
//       }

//     case AlertsColumnKeys.Time:
//     case AlertsColumnKeys.Date:
//       return (a: AlertSession, b: AlertSession) =>
//         normalizeCompareResult(invertIfDescending(compareAlertStartTimes(a, b), sortBy.order))

//     case AlertsColumnKeys.StartLocation:
//       return (a: AlertSession, b: AlertSession) =>
//         normalizeCompareResult(
//           invertIfDescending(
//             simpleCompare(getStartLocation(a), getStartLocation(b)) || compareAlertStartTimes(a, b),
//             sortBy.order,
//           ),
//         )

//     case AlertsColumnKeys.EndLocation:
//       return (a: AlertSession, b: AlertSession) =>
//         normalizeCompareResult(
//           invertIfDescending(
//             simpleCompare(getEndLocation(a), getEndLocation(b)) || compareAlertStartTimes(a, b),
//             sortBy.order,
//           ),
//         )

//     default:
//       console.warn(`Unhandled sort column  ${sortBy.field}`)
//       return () => 0
//   }
// }

export function filterAlertsByHealthLabel(alerts: AlertSession[], healthLabel: string) {
  return alerts.filter(a => getAlertHealth(a) === healthLabel)
}

export function searchAlerts(alerts: AlertSession[], searchBy: string): AlertSession[] {
  // This function is very expensive, only execute if necessary
  // TODO: Look for ways to improve efficiency
  if (searchBy.length < 2) return alerts
  const searchArray = _.flatten(
    alerts.map(alert => [
      [getStartLocation(alert), alert.id],
      [getEndLocation(alert), alert.id],
      [alert.resolverName, alert.id],
      [alert.initiatorName, alert.id],
      [getAlertHealth(alert), alert.id],
      [getResolution(alert), alert.id],
      [getAlertDateString(alert), alert.id],
      [getAlertTimeString(alert), alert.id],
    ]),
  )
  // @ts-ignore
  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)

  return alerts.filter(alert => searchResultsIds.includes(alert.id))
}

export function downloadCsv(alerts: AlertSession[]) {
  const orgs = Orgs.store.getState().orgsById
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_VIEW)

  const fields = [
    translation.id,
    translation.csv_property_name,
    translation.csv_initiator_name,
    translation.csv_resolver_name,
    translation.health,
    translation.responders,
    translation.resolution,
    translation.csv_first_loc,
    translation.csv_last_loc,
    translation.csv_start_date,
    translation.csv_end_date,
    translation.csv_org_id,
  ]
  const data = alerts.map(alertSession => {
    const { startTime, endTime } = getAlertTimes(alertSession)

    return [
      alertSession.id,
      orgs[alertSession.orgId]?.name,
      alertSession.initiatorName,
      alertSession.resolverName,
      translation[getAlertHealth(alertSession)],
      getRespondersCount(alertSession),
      getResolution(alertSession),
      getStartLocation(alertSession),
      getEndLocation(alertSession),
      startTime.toISOString(),
      endTime ? endTime.toISOString() : '',
      alertSession.orgId,
    ]
  })

  const name = `${Orgs.getSelectedOrgName()} ${translation.alerts}`.trim()

  generateCSV(data, fields, name)
}

export const getLastInteractionTime = (alertSession: AlertSession): number => {
  const { resolveTime, firstArrivedTime } = alertSession
  return firstArrivedTime || resolveTime || Date.now() / 1000
}

export const getAlertDuration = (alert: AlertSession): number => {
  const { firstTime } = alert
  return getLastInteractionTime(alert) - firstTime
}

function getStartLocation(alert: AlertSession) {
  const common: TranslationGroup = trans.common()
  const { firstLocation } = alert
  return `${common.room} ${firstLocation?.room || common.na}, ${common.floor} ${firstLocation?.floor || common.na}`
}

function getEndLocation(alert: AlertSession) {
  const { status, responders, resolverUserId, lastLocation } = alert
  const resolverData = responders.find(r => r.userId === resolverUserId)
  const common: TranslationGroup = trans.common()

  const lastInteractionLocation =
    status === HealthTypesKeys.RESOLVED && resolverData ? resolverData.location : lastLocation

  if (!lastInteractionLocation?.room || !lastInteractionLocation?.floor) {
    return getStartLocation(alert)
  }

  return `${common.room} ${lastInteractionLocation?.room || common.na}, ${common.floor} ${
    lastInteractionLocation.floor || common.na
  }`
}

function getRespondersCount(alert: AlertSession) {
  if (alert.status !== HealthTypesKeys.RESOLVED) return alert.activeRespondersCount || 0
  return alert.responders.length || 0
}

export function getResolution(alert: AlertSession) {
  const { timeSpan } = getAlertTimes(alert)
  const { responders } = alert
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_HISTORY)

  return alert.status === HealthTypesKeys.RESOLVED
    ? `${timeSpan?.replace(translation.ago, '')}`
    : responders.length > 0
    ? translation.in_progress
    : translation.ongoing
}

function normalizeCompareResult(r: number) {
  return simpleCompare(r, 0)
}

export const handleDownloadCSV = (orgId: string) => {
  return getV5AlertsCSV(orgId)
}
